import React from 'react';
import styles from './Box.module.css';
import {Base, BaseProps} from '../../Internal/Base';
import clsx from 'clsx';
import {Spacing} from '@treatwell/design-tokens';

const defaultTag = 'div';
export const allowedTags = [
  defaultTag,
  'span',
  'aside',
  'section',
  'article',
  'header',
  'ul',
  'dl',
  'ol',
] as const;
type AllowedTags = typeof allowedTags[number];

export type BoxProps<T extends AllowedTags = typeof defaultTag> = {
  children: React.ReactNode;
  /** Set the padding on device size up to 520px. */
  padding?: Spacing | 'none';
  /** Set the padding on device size 520px and greater. */
  paddingWide?: Spacing | 'none';
} & BaseProps<T>;

const styleWideMap = {
  none: styles.noneWide,
  xxs: styles.xxsWide,
  xs: styles.xsWide,
  sm: styles.smWide,
  md: styles.mdWide,
  lg: styles.lgWide,
  xl: styles.xlWide,
  xxl: styles.xxlWide,
};

export const Box = <T extends AllowedTags>({
  children,
  className,
  padding = 'md',
  paddingWide = 'lg',
  ...restProps
}: BoxProps<T>) => {
  const boxClassName = clsx(
    styles.box,
    className,
    styles[padding],
    styleWideMap[paddingWide]
  );
  return (
    <Base className={boxClassName} {...restProps}>
      {children}
    </Base>
  );
};

Box.displayName = 'Box';
